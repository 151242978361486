import React from "react";
import { Context } from "./../../Provider";
import {
  Card,
  Input,
  Button,
  Modal,
  Form,
  Row,
  Col,
  AutoComplete,
  message,
} from "antd";
import "./StadiumDetail.scss";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import StarRatings from "react-star-ratings";
import RestaurantModal from "./AddRestaurantModal/RestaurantModal";
import EditStadiumModal from "./EditStadiumModal";
import ConnectToTeamModal from "./ConnectToTeam";
import MapComponent from "./LocationComponent/MapComponent";
import API from "../../helper/api";
import { showErrorToast, showSuccessToast } from "../../helper/toaster";
const StadiumDetail = (props) => {
  const {
    stadiums,
    isLoading,
    setIsLoading,
    stadiumLat,
    stadiumLng,
    setStadiumLat,
    setStadiumLng,
  } = React.useContext(Context);
  const [filteredStadium, setFilteredStadium] = React.useState("");
  const [restaurantModal, setRestaurantModal] = React.useState(false);
  const [eidtReviewModal, setEidtReviewModal] = React.useState(false);
  const [connectToTeamModal, setConnectToTeamModal] = React.useState(false);
  const [teamStoreModalVisibility, setTeamStoreModalVisibility] =
    React.useState(false);

  const toggleRestaurantModal = () => setRestaurantModal(!restaurantModal);
  const toggleEidtReviewModal = () => setEidtReviewModal(!eidtReviewModal);
  const toggleConnectToTeamModal = () =>
    setConnectToTeamModal(!connectToTeamModal);
  const toggleTeamStoreModal = () =>
    setTeamStoreModalVisibility(!teamStoreModalVisibility);

  const [stadiumReview, setStadiumReview] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [teamId, setTeamId] = React.useState("");
  const [loadingStadium, setLoadingStadium] = React.useState(false);
  const [teamStores, setTeamStores] = React.useState([]);

  const hideRestaurantModal = (event) => {
    console.log(event);
    if (event) {
      toggleRestaurantModal();
      getStadiumById();
    }
  };
  const hideConnectToTeamModal = (event) => {
    console.log(event);
    if (event) {
      toggleConnectToTeamModal();
      getStadiumById();
    }
  };

  const editStadiumModal = (event) => {
    console.log(event);
    if (event) {
      setEidtReviewModal(!eidtReviewModal);
      getStadiumById();
    }
  };
  console.log(filteredStadium, "filteredStadium");

  const getCountryName = async (lat, lng) => {
    const apiKey = "AIzaSyD2EUbN8GTw0joJ629a5RiZlauA7S65-8M"; 
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;

      if (results && results.length > 0) {
        const addressComponents = results[0].address_components;
        const countryComponent = addressComponents.find((component) =>
          component.types.includes("country")
        );
        if (countryComponent) {
          return countryComponent.long_name; // Return the country name
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocode data", error);
      return null;
    }
  };

  console.log("filteredStadium", filteredStadium);

  const sendStadiumData = async () => {
    let countryName = "";
    if (!filteredStadium?.country) {
      countryName = await getCountryName(
        filteredStadium.coordinates.lat,
        filteredStadium.coordinates.lng
      );
    }
    if (!loadingStadium) {
      setLoadingStadium(true);
      const data = [
        {
          name: filteredStadium.name,
          address: filteredStadium.city,
          coordinates: [
            filteredStadium.coordinates.lng,
            filteredStadium.coordinates.lat,
          ],
          venueCountry: filteredStadium?.country || countryName,
          venueId:filteredStadium.id,
          city:filteredStadium.city
        },
      ];
      console.log("countryName", data);
      API.post(`/attractions-by-location-chat`, { data })
        .then((response) => {
          console.log("Response", response);
          setLoadingStadium(false);
          showSuccessToast(response.data.data.message);
        })
        .catch((err) => {
          console.log("Error", err);
          showErrorToast(err.message || "Something went wrong");
          setLoadingStadium(false);
        });
    }
  };

  const getStadiumById = () => {
    API.get(`/get-stadium/${props.match.params.id}`)
      .then((res) => {
        setFilteredStadium(res.data.data.stadium);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (filteredStadium) {
      API.get(`/get-stadium-reviews/${filteredStadium._id}`)
        .then((res) => {
          if (res?.data?.data?.reviews) setStadiumReview(res.data.data.reviews);
        })
        .catch((err) => console.log(err));
    }
  }, [filteredStadium]);

  React.useEffect(() => {
    getStadiumById();
  }, [props.match.params.id]);

  React.useEffect(() => {
    if (filteredStadium) {
      API.get(`/team-store/${filteredStadium._id}`)
        .then((res) => {
          setTeamStores(res.data.data.teamStores);
        })
        .catch((err) => console.log(err));
    }
  }, [filteredStadium]);

  return (
    <Card title="Stadium Detail" style={{ marginTop: "3em" }}>
      {filteredStadium ? (
        <>
          <Row
            style={{
              width: "70%",
              marginBottom: "1em",
            }}>
            <Col span={12} flex>
              <Row>
                <label className="stadium-detail-label">Name:</label>
                <p className="detail">{filteredStadium.name}</p>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <label className="stadium-detail-label">Total Rating:</label>
                <p className="detail">{filteredStadium.totalRating}</p>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              marginBottom: "1em",
            }}>
            <Col span={12}>
              <label className="stadium-detail-label">Location:</label>
              <div
                style={{ height: "200px", width: "100%", marginTop: "0.5em" }}>
                <MapComponent
                  lat={filteredStadium.coordinates.lat}
                  lng={filteredStadium.coordinates.lng}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <label className="stadium-detail-label">Restaurants:</label>
          </Row>

          <Row style={{ display: "flex", flexWrap: "wrap" }}>
            {filteredStadium.restaurant.map((item, i) => (
              <Card key={i} style={{ margin: "5px", width: "280px" }}>
                <p>{item.name}</p>
                <p>{item.address}</p>
                <div
                  style={{
                    height: "200px",
                    width: "100%",
                    marginTop: "0.5em",
                  }}>
                  <MapComponent
                    lat={item.location.lat}
                    lng={item.location.lng}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    style={{
                      margin: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    danger
                    onClick={() => {
                      var requestOptions = {
                        method: "DELETE",
                      };
                      fetch(
                        `https://www.footballaroundme.com/api/restaurant/${filteredStadium._id}/${item._id}`,
                        requestOptions
                      )
                        .then((res) => res.text())
                        .then((res) => {
                          // const obj = JSON.parse(res);
                          // setFilteredStadium(new Array(obj.data));
                          getStadiumById();
                        })
                        .catch((err) => console.log(err));
                    }}>
                    <DeleteOutlined />
                  </Button>

                  <a
                    href={`http://www.google.com/maps/place/${item.location.lat},${item.location.lng}`}
                    target="_blank">
                    {" "}
                    <Button style={{ margin: "5px" }} type="primary">
                      See in map{" "}
                    </Button>
                  </a>
                </div>
              </Card>
            ))}
          </Row>

          <Row>
            <label className="stadium-detail-label">Reviews:</label>
          </Row>
          <Row>
            {stadiumReview.length > 0 && (
              <Card>
                <div style={{ display: "flex" }}>
                  <div>
                    <label className="review-label">User:</label>
                  </div>
                  <div className="review-detail">
                    <p>{stadiumReview[0].reviewer_name}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <label className="review-label">User Email:</label>
                  </div>
                  <div className="review-detail">
                    <p>{stadiumReview[0].reviewer_email}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <label className="review-label">Team:</label>
                  </div>
                  <div style={{ display: "flex" }} className="review-detail">
                    <img
                      src={stadiumReview[0].team_id.logo}
                      alt="img"
                      className="stadium-detail-logo"></img>
                    <p>{`${stadiumReview[0].team_id.name} (${stadiumReview[0].team_id.country})`}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <label className="review-label">Rating:</label>
                  </div>
                  <div className="review-detail">
                    <StarRatings
                      starRatedColor="#f7db11"
                      numberOfStars={5}
                      starDimension="22px"
                      rating={
                        (stadiumReview[0].food_expense +
                          stadiumReview[0].food_quality +
                          stadiumReview[0].seat_view +
                          stadiumReview[0].stadium_atmosphere +
                          stadiumReview[0].stadium_equipment) /
                        5
                      }
                      starSpacing="3px"></StarRatings>
                  </div>
                </div>
              </Card>
            )}
          </Row>
          {filteredStadium.stadium_structure && (
            <div>
              <Row>
                <label className="stadium-detail-label">Stadium Map</label>
              </Row>
              <Row>
                <img
                  src={filteredStadium.stadium_structure}
                  alt="stadium-map"
                  className="stadium-img"
                />
              </Row>
            </div>
          )}
          <Row>
            <label className="stadium-detail-label">Team Stores:</label>
          </Row>
          {/* <Row style={{ display: "flex", flexWrap: "wrap" }}>
            {teamStores.length > 0 &&
              teamStores.map((item, i) => {
                return (
                  <Card key={i} style={{ marginLeft: "5px" }}>
                    <p>{item.name}</p>
                    <p>{item.address}</p>
                    <div style={{ display: "flex" }}>
                      <img
                        src={item.team.logo}
                        alt="img"
                        className="stadium-detail-logo"
                      ></img>
                      <p>{`${item.team.name} (${item.team.country})`}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Button
                        style={{
                          margin: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        danger
                        onClick={() => {
                          var requestOptions = {
                            method: "DELETE",
                          };
                          fetch(
                            `https://www.footballaroundme.com/api/team-store/${filteredStadium._id}/${item._id}`,
                            requestOptions
                          )
                            .then((res) => res.text())
                            .then((res) => {
                              const obj = JSON.parse(res);
                              setTeamStores(obj.data.teamStores);
                            })
                            .catch((err) => console.log(err));
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                      <a
                        href={`http://www.google.com/maps/place/${item.coordinates.lat},${item.coordinates.lng}`}
                        target="_blank"
                      >
                        <Button type="primary" style={{ margin: "5px" }}>
                          See in map
                        </Button>
                      </a>
                    </div>
                  </Card>
                );
              })}
          </Row> */}
        </>
      ) : (
        <LoadingOutlined />
      )}
      <Row>
        <Button
          onClick={toggleEidtReviewModal}
          type="primary"
          style={{ marginTop: "1em" }}>
          Edit Details
        </Button>
        {/* <Button
          style={{ marginTop: "1em", marginLeft: "1em" }}
          type="primary"
          onClick={toggleTeamStoreModal}
        >
          Add Team Store
        </Button> */}

        {/* <Button style={{ marginTop: "1em", marginLeft: "1em" }} onClick={()=> message.warning('Comming Soon')} type="primary"> Add Team Store </Button> */}

        <Button
          type="primary"
          style={{ marginTop: "1em", marginLeft: "1em" }}
          onClick={toggleRestaurantModal}>
          Add Restaurant
        </Button>

        <Button
          type="primary"
          style={{ marginTop: "1em", marginLeft: "1em" }}
          onClick={toggleConnectToTeamModal}>
          Connect To Team
        </Button>

        <Button
          type="primary"
          style={{ marginTop: "1em", marginLeft: "1em" }}
          onClick={sendStadiumData}>
          Create Attraction
          {loadingStadium && <LoadingOutlined className="loader-button" />}
        </Button>
      </Row>

      <EditStadiumModal
        stadium={filteredStadium}
        showModal={eidtReviewModal}
        editStadiumModal={editStadiumModal}
      />

      <ConnectToTeamModal
        showModal={connectToTeamModal}
        hideConnectToTeamModal={hideConnectToTeamModal}
        stadiumId={filteredStadium ? filteredStadium._id : ""}
      />

      {/* <Modal
        title="Add Team Store"
        visible={teamStoreModalVisibility}
        footer={false}
        onCancel={toggleTeamStoreModal}
      >
        <Form
          initialValues={{
            teamStoreName: "",
            teamStoreAddress: "",
            teamStoreLat: "",
            teamStoreLng: "",
          }}
          onFinish={(values) => {
            var urlencoded = new URLSearchParams();
            urlencoded.append("stadium", filteredStadium._id);
            urlencoded.append("team", teamId);
            urlencoded.append("name", values.teamStoreName);
            urlencoded.append("address", values.teamStoreAddress);
            urlencoded.append("lat", values.teamStoreLat);
            urlencoded.append("lng", values.teamStoreLng);

            var requestOptions = {
              method: "POST",
              body: urlencoded,
              redirect: "follow",
            };
            fetch(
              `https://www.footballaroundme.com/api/team-store`,
              requestOptions
            )
              .then((res) => res.text())
              .then((res) => {
                const obj = JSON.parse(res);
                setTeamStores(new Array(obj.data));
              })
              .then(() => toggleTeamStoreModal())
              .catch((err) => console.log(err));
          }}
        >
          <div>
            <label className="label-restaurant-detail">Search Team</label>
            <Form.Item name="team">
              <AutoComplete
                onSelect={(value, option) => {
                  setTeamId(option.key);
                }}
                options={teams}
                onSearch={(value) => {
                  if (value.length > 2) {
                    console.log(value);
                    axios
                      .get(
                        `https://www.footballaroundme.com/api/league-teams-search?text=${value}`
                      )
                      .then((res) => {
                        console.log(res);
                        const arr = [];
                        res.data.data.teams.forEach((item) =>
                          arr.push({ item, value: item.name, key: item._id })
                        );
                        setTeams(arr);
                      })
                      .catch((err) => console.log(err));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div>
            <label className="label-restaurant-detail">Name</label>
            <Form.Item name="teamStoreName">
              <Input />
            </Form.Item>
          </div>
          <div>
            <label className="label-restaurant-detail">Address</label>
            <Form.Item name="teamStoreAddress">
              <Input />
            </Form.Item>
          </div>
          <div>
            <label className="label-restaurant-detail">Latitude</label>
            <Form.Item name="teamStoreLat">
              <Input />
            </Form.Item>
          </div>
          <div>
            <label className="label-restaurant-detail">Longitude</label>
            <Form.Item name="teamStoreLng">
              <Input />
            </Form.Item>
          </div>
          <div>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                submit
                {isLoading ? <LoadingOutlined /> : null}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal> */}

      <RestaurantModal
        showModal={restaurantModal}
        hideRestaurantModal={hideRestaurantModal}
        stadiumId={filteredStadium ? filteredStadium._id : ""}
      />
    </Card>
  );
};

export default StadiumDetail;
